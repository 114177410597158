import React from "react"
// import {  } from '@builder.io/gatsby';
import { builder, BuilderComponent } from "@builder.io/react"
import { graphql } from "gatsby"

builder.init("644b3df34c7d4cec94bcd533ff27ac50")

const MyComponent = props => {
  const content = props.data?.allBuilderModels.new[0]?.content

  return <BuilderComponent content={content} model="new" />
}
export default MyComponent

export const query = graphql`
  query($path: String!) {
    allBuilderModels {
      new(target: { urlPath: $path }, limit: 1, options: { cachebust: true }) {
        content
      }
    }
  }
`
